/* eslint-env serviceworker */
/* globals _ ZencastrAudioProcessor */

var self = this

/**
 * We will save the stats that come out of the mic processor in this object
 * On 'reinit' we'll need to restart the processor.
 * When the second processor stops, we'll compile those stats with these ones and fire the final event
 * @type {Object}
 */
var _statsCache = null

class GenericWasmProcessor {
  constructor (processorOptions) {
    this.isLastReceived = false
    this.format = 'mp3'
    this.processorOptions = { ...processorOptions }
  }

  lastReceived () {
    this.isLastReceived = true
  }

  signalPipeline (pipelineName) {
    var pipeline = self.pipeline[pipelineName][0]
    if (pipeline) {
      // new Uint8Array()
      // new Int16Array()
      pipeline.trigger('incomingChunk', null, true)
    }
  }

  signalMainThread (format = 'mp3', type = 'microphone') {
    self.postMessage({
      command: 'lastChunkProcessed',
      format: format,
      type: type
    })
  }

  signalTrackFinalization () {
    if (this.name === 'microphone') {
      this.signalPipeline('mp3microphone')
      this.signalMainThread()

      // in case the user also had a wav pipeline
      if (self.pipeline['wavmicrophone']) {
        this.signalPipeline('wavmicrophone')
        this.signalMainThread('wav')
      }
    } else if (this.name === 'soundboard') {
      this.signalPipeline('mp3soundboard')
      this.signalMainThread('mp3', 'soundboard')
    }
  }
}

class RawWasmProcessor extends GenericWasmProcessor {
  constructor (name, processorOptions, sampleRate) {
    super(processorOptions)
    this.name = name
    this.format = 'wav'
    this.processorOptions.rawSampleRate = sampleRate
  }

  /**
   * Process raw input from the ScriptProcessor
   * @param  {Float32Array} inputBuffer Raw float data
   */
  processRawData (inputBuffer) {
    var data = new Uint8Array(inputBuffer.buffer, inputBuffer.byteOffset, inputBuffer.byteLength)
    if (!this.isLastReceived) {
      if (this.processor) {
        this.processor.process(data)
      } else {
        this.processor = new ZencastrAudioProcessor(data, this.processorOptions)
      }
    } else {
      console.error('Getting additional chunks after last')
    }
  }

  lastReceived () {
    super.lastReceived()
    this.processor.process()

    this.signalTrackFinalization()
  }
}

class WebmWasmProcessor extends GenericWasmProcessor {
  constructor (name, processorOptions) {
    super(processorOptions)
    this.name = name
    this.blobsToProcess = [] // Fifo for async Blobs processing
  }

  processChunk (chunk) {
    if (this.isLastReceived) {
      console.error('Getting additional chunks after last')
      return
    }

    if (chunk instanceof Blob) {
      // If chunk is small, cache it for the next time
      if (chunk.size < 1024) {
        this.cachedBlob = this.cachedBlob ? new Blob([this.cachedBlob, chunk]) : chunk
        console.warn('Blob is too small to process', chunk.size)
        return
      }
      // If we have big chunk and cached chunk, attach cached to the new one
      if (this.cachedBlob) {
        chunk = new Blob([this.cachedBlob, chunk])
        this.cachedBlob = null
      }
    }

    this.blobsToProcess.push(chunk)
    // if it is the only blob in queue process it
    if (this.blobsToProcess.length === 1) {
      this._processNextBlob()
    }
  }

  _processNextBlob () {
    console.assert(this.blobsToProcess.length !== 0, 'Trying to process empty blobs queue')

    // next thing in queue
    var blob = this.blobsToProcess[0]

    // if we have a command
    while (typeof blob === 'string') {
      // reinit the processor. used when the input stream changed
      if (blob === 'reinit') {
        if (this.processor) {
          // finalise and destroy the previous
          this.processor.setNoTrailer()
          this.processor.process()
          this.processor.deinit()
          this.processor = null
        }
      }
      this.blobsToProcess.shift()
      // if the queue is empty, return
      if (this.blobsToProcess.length === 0) {
        return
      } else {
        // if we have new blobs in queue, process them next
        blob = this.blobsToProcess[0]
      }
    }

    blob.arrayBuffer().then((arrayBuffer) => {
      var data = new Uint8Array(arrayBuffer)
      if (this.processor) {
        try {
          this.processor.process(data)
        } catch (e) {
          // if .process() throws then we need to abort the recording
          self.postMessage({
            command: 'abort',
            error: e.message
          })
        }
      } else {
        this.processor = new ZencastrAudioProcessor(data, this.processorOptions)
      }
      this.blobsToProcess.shift()
      if (this.blobsToProcess.length) {
        this._processNextBlob()
      } else if (this.isLastReceived) { // queue is empty, and no more blobs are expected
        this.processLast()
      }
    })
  }

  /**
   * Called when we need to reinit the wasm audio pipeline
   * It actually stops the old processor. We will start a new one afterm which will not add footers and headers to mp3
   * @param  {Number} delay How many miliseconds we need to add
   *                        This represents the time between when we stopped the old media recorder
   *                        and the first chunk from the new one
   */
  processReinit (delay) {
    // change type of the next created processor
    this.processorOptions.startDelayMs = delay
    this.processorOptions.audioType = ZencastrAudioProcessor.prototype.AudioType.MicrophoneNoHeader
    this.processChunk('reinit')
  }

  /**
   * Called when we've reicevd the isLast flag from the main thread
   */
  lastReceived () {
    super.lastReceived()
    if (this.blobsToProcess.length === 0) {
      this.processLast()
    }
  }

  processLast () {
    this.processor.process()
    this.signalTrackFinalization()
  }
}

this.onmessage = function (e) {
  switch (e.data.command) {
    case 'init':
      this.init(e.data.config)
      break
    case 'addUpstreamPort':
      // there should only be one
      this.upstreamPort = e.data.port
      this.upstreamPort.onmessage = this.handleUpstreamMessage.bind(this)
      break
    case 'addPipeline':
      this.addPipeline(e.data.config)
      break
    case 'getWebmInfo':
      this.getWebmInfo(e.data.data)
      break
  }
}

this.handleUpstreamMessage = function (e) {
  // this.debugEnabled && console.log('got message', e.data)

  var tag = e.data.tag
  var sampleRate = e.data.sampleRate

  // check the tag for this chunk
  if (tag) {
    console.assert(this.rawInput !== true, 'raw input flag was set but received webm audio')

    // we are recording using the MediaRecorder
    var processor = this.getProcessor(tag)
    var chunk = e.data.chunk
    if (chunk) {
      processor.processChunk(chunk)
    }
    if (e.data.reinit) {
      processor.processReinit(e.data.delay)
    }
    if (e.data.isLast) {
      processor.lastReceived()
    }
  } else { // raw data
    // just making sure the flag is set correctly
    console.assert(this.rawInput === true, 'raw input flag was not set and received raw audio')

    var microphone = e.data.microphone
    if (microphone) {
      this.getProcessor('microphone', sampleRate).processRawData(microphone)
    }

    var soundboard = e.data.soundboard
    if (soundboard) {
      this.getProcessor('soundboard', sampleRate).processRawData(soundboard)
    }

    // when receiving isLast, stop all processors
    if (e.data.isLast) {
      for (var key in this.processors) {
        this.processors[key].lastReceived()
      }
    }
  }
}

this.init = function (config = {nodes: []}) {
  if (config.wasmLocation) {
    self.wasmLocation = config.wasmLocation
  }

  // import any scripts used by multiple nodes so we don't have to load them for each node
  importScripts.apply(self, config.commonScripts)

  this.debugEnabled = !!config.debug

  // if the pipeline will receive raw pcm instead of webm
  this.rawInput = config.rawInput
  // if we should also output pcm
  this.rawOutput = config.rawOutput
  // if we shoudl record soundboard
  this.recordSoundboard = config.recordSoundboard

  this.upstreamPort = config.upstreamPort

  if (this.upstreamPort) this.upstreamPort.onmessage = this.handleUpstreamMessage.bind(this)

  this.pipeline = {}

  // wait for the wasm module to be instantiated
  // and signal to the main thread
  ZencastrAudioProcessor.ready.then(function () {
    self.postMessage({command: 'ready'})
    ZencastrAudioProcessor.setLogVerbosity(0)
  })

  this.processors = {}

  this.processorOptions = {
    onMp3: (data, isLast) => { self.mp3DataAvailable(data, isLast) },
    onStats: (data) => { self.parseStats(data, 'microphone') },
    mp3ChunkSize: config.mp3ChunkSize || 65536,
    audioType: ZencastrAudioProcessor.prototype.AudioType.Microphone
  }

  if (this.rawOutput) {
    _.extend(this.processorOptions, {
      onRaw: (data, isLast) => { self.rawDataAvailable(data, isLast) },
      rawChunkSize: config.rawChunkSize || 88200
    })
  }

  if (this.recordSoundboard) {
    this.soundboardProcessorOptions = {
      onMp3: (data, isLast) => { self.soundboardDataAvailable(data, isLast) },
      onStats: (data) => { self.parseStats(data, 'soundboard') },
      mp3ChunkSize: config.mp3ChunkSize || 65536,
      audioType: ZencastrAudioProcessor.prototype.AudioType.Soundboard
    }
  }

  if (!this.debugEnabled) {
    this.replaceLogging()
  }
}

this.rawDataAvailable = function (data) {
  // this.debugEnabled && console.log('Raw data available', data.constructor.name, data.length)
  this.pipeline['wavmicrophone'][0].trigger('incomingChunk', data.slice(0), false)
}

this.mp3DataAvailable = function (data) {
  // this.debugEnabled && console.log('mic mp3 data available', data.constructor.name, data.length)
  this.pipeline['mp3microphone'][0].trigger('incomingChunk', data.slice(0), false)
}

this.soundboardDataAvailable = function (data, isLast) {
  // this.debugEnabled && console.log('soundboard mp3 data available', data.constructor.name, data.length)
  this.pipeline['mp3soundboard'][0].trigger('incomingChunk', data.slice(0), false)
}

this.getProcessor = function (name, sampleRate) {
  var options = name === 'soundboard' ? this.soundboardProcessorOptions : this.processorOptions
  if (!(name in this.processors)) {
    this.processors[name] = this.rawInput ? new RawWasmProcessor(name, options, sampleRate) : new WebmWasmProcessor(name, options)
  }
  return this.processors[name]
}

this.addPipeline = function (config) {
  // console.log(config)
  config.nodes.forEach(function (nodeConf) {
    var name = config.name
    if (!this.pipeline[name]) {
      this.pipeline[name] = []
    }

    var node = this.createPipelineNode(nodeConf, this.pipeline[name])
    this.pushPipelineNode(node, this.pipeline[name])
  })
}

this.createPipelineNode = function (nodeConf, pipeline = this.pipeline) {
  // load scripts / dependencies for the processor
  nodeConf.scripts && importScripts.apply(self, nodeConf.scripts)

  // a bit hacky but some nodes need to reference others (CloudStore needs MemoryStore)
  nodeConf.constructorConf.pipeline = pipeline

  // construct node
  var nodeInstance = new this[nodeConf.constructorName](nodeConf.constructorConf)

  return nodeInstance
}

this.pushPipelineNode = function (node, pipeline) {
  if (pipeline.length) {
    // stop listening to the previous end node for output chunks
    var endNode = pipeline[pipeline.length - 1]

    // pipe last end node into new end node
    endNode.pipe(node)
  }

  pipeline.push(node)
}

/**
 * Used to get webm stats for an audio blob
 * Useful when trying to find the true sample rate from an input device
 * @param  {Blob} blob Audio blob
 */
this.getWebmInfo = function (blob) {
  if (!(blob instanceof Blob)) return

  blob.arrayBuffer().then((arrayBuffer) => {
    var data = new Uint8Array(arrayBuffer)
    var processor = new ZencastrAudioProcessor(data, {
      onStats: (stats) => {
        this.postMessage({
          command: 'webmInfo',
          data: stats
        })
      }
    })

    processor.deinit()
  })
}

this.parseStats = function (data, tag) {
  if (data.sample_rate) {
    if (self.processors[tag]) {
      self.processors[tag].sampleRate = data.sample_rate
      self.processors[tag].channels = data.channels
    }
  } else if (data.samples_decoded) {
    var toSend = {...data}

    if (tag === 'microphone') {
      // if we have stats cached
      if (_statsCache) {
        for (var key in toSend) {
          // just sum up the values
          toSend[key] += _statsCache[key]
        }
      }

      // make a copy of the stats object in case we need it
      _statsCache = {...data}
    }

    self.postMessage({
      command: 'stats',
      tag: tag,
      message: {
        // data contains:
        // samples_decoded
        // samples_encoded
        // frames_decoded
        // frames_encoded
        ...toSend,
        sampleRate: self.processors[tag].sampleRate,
        channels: self.processors[tag].channels
      }
    })
  } else {
    console.log('Received strange stats message', tag, data)
  }
}

/**
 * Replace the native console methods so that we can surface logs from inside the worker to the main thread
 */
this.replaceLogging = function () {
  // method borrowed from logger.js
  var createLogLine = function (args) {
    // make it an array
    args = Array.prototype.slice.call(args)

    var string = ''
    try {
      args.forEach(function (arg) {
        if (typeof arg === 'object') {
          // if it's type ErrorEvent it will have a message attribute
          if (arg.message) {
            string += arg.message
            // if it's a blob
          } else if (arg instanceof Blob) {
            string += 'Blob, size ' + arg.size
          } else if (ArrayBuffer.isView(arg)) {
            string += arg.constructor.name + ' ' + arg.length
          } else {
            // else, just stringify it
            string += JSON.stringify(arg)
          }
        } else {
          // if string, array, etc
          string += arg.toString()
        }

        // add a sepparator between args
        string += ' '
      })

      return string
    } catch (e) {
      // in case we have any problem parsing the args
      // just glue them together and return
      return Array.prototype.join.call(args, ' ')
    }
  }

  self.console.log = function () {
    self.postMessage({
      command: 'log',
      message: createLogLine(arguments)
    })
  }

  self.console.warn = function () {
    self.postMessage({
      command: 'log',
      type: 'warn',
      message: createLogLine(arguments)
    })
  }

  self.console.error = function () {
    self.postMessage({
      command: 'log',
      type: 'error',
      message: createLogLine(arguments)
    })
  }
}
